
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Anchor extends Vue {
  /**
   * Property 'to' is forwarded to <router-link> component
   * so validation is not performed here.
   */
  @Prop({
    type: [String, Object],
    required: true,
  })
  public to!: (string | object)[];
}
