
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  SUPPORT_PAGE_HEADER_ACTION_APPEARENCES,
  SUPPORT_PAGE_HEADER_ACTION_TYPES,
} from '@/utility/definitions';

@Component
export default class PageHeaderAction extends Vue {
  @Prop({
    type: String,
    default: 'primary',
    validator(value: string) {
      return SUPPORT_PAGE_HEADER_ACTION_TYPES.indexOf(value) > -1;
    },
  })
  public type!: string;

  @Prop({
    type: String,
    default: 'default',
    validator(value: string) {
      return SUPPORT_PAGE_HEADER_ACTION_APPEARENCES.indexOf(value) > -1;
    },
  })
  public appearance!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  public modDisabled!: boolean;

  /**
   * Computed the button component attributes.
   *
   * @return {object}
   */
  protected get buttonAttributes(): object {
    return {
      'mod-error': this.appearance === 'error',
      'mod-primary': this.type === 'primary',
      'mod-secondary': this.type === 'secondary',
      'mod-tertiary': this.type === 'tertiary',
      'mod-disabled': this.modDisabled,
    };
  }
}
