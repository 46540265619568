
import { Component, Prop, Vue } from 'vue-property-decorator';
import Anchor from './Anchor.vue';
/**
 * Generic search component which provides support filters and pager.
 */
@Component({
  components: {
    Anchor,
  },
})
export default class PageHeader extends Vue {
  @Prop({ required: true })
  protected title!: string;
}
