
import { Component, Prop, Vue } from 'vue-property-decorator';
import PageHeaderAction from './PageHeaderAction.vue';

@Component({
  components: {
    PageHeaderAction,
  },
})
export default class PageHeaderNavigationAction extends Vue {
  /**
   * Property 'to' is forwarded to <router-link> component
   * so validation is not performed here.
   */
  @Prop({
    type: [String, Object],
    required: true,
  })
  public to!: (string | object)[];
}
